import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";

const AppStepsIndicator = props => {
    return (
        <div className="app-steps-indicator">
            <div className="app-steps-indicator__container container">
                <p className="app-steps-indicator__number">
                    <FormattedHTMLMessage id={`stepsIndicator.${props.step - 1}.number`} />
                </p>
                <h2 className="app-steps-indicator__title text-bold">
                    <FormattedMessage id={`stepsIndicator.${props.step - 1}.title`} />
                </h2>
            </div>
        </div>
    );
};

export default AppStepsIndicator;
