import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { navigate, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import { observer } from "mobx-react";

import api from "../../service/api";
import { getBase64, compressImageFile, errorKeyNavigateSystemErrorPage, sanitizeError } from "../../app.utils";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import * as constants from "../../app.constant";
import alertStore from "../../store/alertStore";
import deviceStore from "../../store/deviceStore";
import AppLayout from "../../components/shared/AppLayout";
import AppStepsIndicator from "../../components/shared/AppStepsIndicator";
import deviceFrontImgWithSerialImg from "../../assets/images/page-verify-wearables-photo/upload-example-device-front-with-imei.png";
import deviceBackWithReceiptImg from "../../assets/images/page-verify-wearables-photo/upload-example-device-back-with-receipt.png";
import deviceFlashlightFunctionImg from "../../assets/images/page-verify-wearables-photo/upload-example-device-flashlight-function.png";
import deviceChargingFunctionImg from "../../assets/images/page-verify-wearables-photo/upload-example-device-charging-function.png";
import deviceWifiFunctionImg from "../../assets/images/page-verify-wearables-photo/upload-example-device-wifi-function.png";
import deviceSensorFunctionImg from "../../assets/images/page-verify-wearables-photo/upload-example-device-sensor-function.png";
import successIcon from "../../assets/images/valid.svg";
import useIsMount from "../../hooks/use-is-mount";

const inputs = [
    { label: "verifyWearablesPhoto.form.fields.0.label", name: "deviceFrontImgWithSerial", note: "verifyWearablesPhoto.form.fields.0.note", paperType: "1", exampleImageSrc: deviceFrontImgWithSerialImg },
    { label: "verifyWearablesPhoto.form.fields.1.label", name: "deviceBackWithReceipt", note: "verifyWearablesPhoto.form.fields.1.note", paperType: "2", exampleImageSrc: deviceBackWithReceiptImg },
    { label: "verifyWearablesPhoto.form.fields.2.label", name: "deviceFlashlightFunction", note: "verifyWearablesPhoto.form.fields.2.note", paperType: "4", exampleImageSrc: deviceFlashlightFunctionImg },
    { label: "verifyWearablesPhoto.form.fields.3.label", name: "deviceChargingFunction", note: "verifyWearablesPhoto.form.fields.3.note", paperType: "8", exampleImageSrc: deviceChargingFunctionImg },
    { label: "verifyWearablesPhoto.form.fields.4.label", name: "deviceWifiFunction", note: "verifyWearablesPhoto.form.fields.4.note", paperType: "16", exampleImageSrc: deviceWifiFunctionImg },
    { label: "verifyWearablesPhoto.form.fields.5.label", name: "deviceSensorFunction", note: "verifyWearablesPhoto.form.fields.5.note" , paperType: "32", exampleImageSrc: deviceSensorFunctionImg },
];

const initialValues = {
    deviceFrontImgWithSerial: null,
    deviceBackWithReceipt: null,
    deviceFlashlightFunction: null,
    deviceChargingFunction: null,
    deviceWifiFunction: null,
    deviceSensorFunction: null,
};

const fileTypes = [ "image/jpg", "image/jpeg", "image/png" ];
const fileLimit = 20000000;
const fileErrors = [
    { type: "fileSize", error: "File Size is too large" },
    { type: "fileType", error: "Unsupported File Format" }
]

const PageVerifyWearablesPhoto = observer(() => {
    const [ selectedOption, setSelectedOption ] = useState( inputs[0] );
    const uploadImageNoSuccessPromisses = useRef( 0 );
    const orderNumber = deviceStore.deviceOrderNumber;
    const isMount = useIsMount();
    const location = useLocation();

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            deviceFrontImgWithSerial: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceBackWithReceipt: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceFlashlightFunction: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceChargingFunction: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceWifiFunction: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
            deviceSensorFunction: Yup.mixed()
                .test( fileErrors[0].type, fileErrors[0].error, value => value?.size <= fileLimit )
                .test( fileErrors[1].type, fileErrors[1].error, value => fileTypes.includes( value?.type )),
        }),
        onSubmit: ( values, action ) => {
            onHandleImagesToBase64(values);
        },
    });


    const onHandleImagesToBase64 = async ( files ) => {
        uploadImageNoSuccessPromisses.current = 0;

        for (const key of Object.keys(files)) {
            const file = files[key];
            if(file) {
                const fileExtension = file.name.split('.').pop();
                const paperType = inputs.filter(i => i.name === key)[0].paperType;
                const payload = {
                    orderNumber: orderNumber,
                    paperType: paperType,
                    fileExtension: fileExtension,
                    base64Data: null,
                }

                compressImageFile( file, ( compressedFile ) => {
                    getBase64( compressedFile, ( base64Image )  => {
                        const removedTypeFromBase64String = base64Image.split(',')[1];
                        payload.base64Data = removedTypeFromBase64String;
                        onHandleUploadSubmit( payload );
                    });
                });
            }
        }

    }

    const onHandleUploadSubmit = async ( payload ) => {
        try {
            await api.post.uploadDeviceImage(payload);
            uploadImageNoSuccessPromisses.current += 1;

            if (uploadImageNoSuccessPromisses.current === 6) {
                const payload = { orderNumber: orderNumber };
                onHandleCompleteOrder(payload);
            }
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
            formik.setSubmitting(false);
        }
    }

    const onHandleFileUpload = ( event ) => {
        const name = event.currentTarget.name;
        const file = event.currentTarget.files[0];

        formik.setFieldValue( name, file );

        event.currentTarget.value = "";
    }

    const onHandleSelectOption = ( index ) => {
        setSelectedOption( inputs[index] );
    }

    const onHandleCompleteOrder = async ( payload ) => {
        try {
            const response = await api.post.completeOrder(payload);
            deviceStore.updateCompleteOrder(response.data);
            navigate(constants.ROUTES.UPLOAD_SUCCESS);
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
            formik.setSubmitting(false);
        }
    }

    useEffect(() => {
        if (isMount) {
            const searchParams = parse(location.search);
            const orderNumberParam = searchParams?.orderNumberId;
            
            if (orderNumberParam) {
                deviceStore.updateDeviceOrderNumber(orderNumberParam);
            }
        }
    }, [isMount, location]);

    useEffect(() => {
        if (isMount && !deviceStore.deviceOrderNumber) {
            navigate(constants.ROUTES.PLANS);
        }
    }, [isMount]);
    
    return (

        <AppLayout hasHeader={ true } hasHeaderLogo={ true } isBackBtn={ true } hasFooter={ true } isLoading={formik.isSubmitting}>

            <AppStepsIndicator step="4" />

            <div className="app-page page-verify-wearables-photo">

                <div className="verify-wearables-photo">

                    <div className="container">

                        <form className="verify-wearables-photo__form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>

                            {
                                formik.values[selectedOption.name] && formik.errors[selectedOption.name] ? <div className="form-group__error invalid-feedback">{ formik.errors[selectedOption.name] }</div> : null
                            }

                            <label className="verify-wearables-photo__input">
                                <span><FormattedMessage id="verifyWearablesPhoto.form.cta" /></span>
                                <input hidden type="file" name={ selectedOption.name } onChange={ onHandleFileUpload } />
                            </label>

                            <p>
                                <FormattedMessage id="verifyWearablesPhoto.headline" />
                            </p>

                            <div className="verify-wearables-photo__device-select form-row">
                                {
                                    inputs.map(( input, index ) => {
                                        const inputName = input.name;
                                        const key = `${ inputName }-${ index }`;
                                        const active = inputName === selectedOption.name ? "verify-wearables-photo__upload-button--is-active" : "";
                                        const isUploaded = formik.values[inputName];
                                        const uploadedClassName = isUploaded ? "verify-wearables-photo__upload-button--is-uploaded text-bold" : "";

                                        return (
                                            <div className="col-6 col-md-4" key={ key }>
                                                <button className={`verify-wearables-photo__upload-button ${ active } ${ uploadedClassName }`} type="button" onClick={() => onHandleSelectOption( index )}>
                                                    <FormattedMessage id={input.label} />
                                                    {isUploaded ? (
                                                        <img className="verify-wearables-photo__upload-button-uploaded-icon img-fluid" src={successIcon} alt="" width="18" />
                                                    ) : null}
                                                </button>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                            <p>
                                <FormattedHTMLMessage id={selectedOption.note} />
                            </p>

                            <div className="verify-wearables-photo__device-photo-preview">
                                <img className="img-fluid" alt="" src={ formik.values[selectedOption.name] ? URL.createObjectURL( formik.values[selectedOption.name] ) : selectedOption.exampleImageSrc } />
                            </div>

                            {formik.values[selectedOption.name] ? (
                                <p className="verify-wearables-photo__device-photo-filename">
                                    {formik.values[selectedOption.name]?.name}
                                </p>
                            ) : null}

                            <div className="verify-wearables-photo__order-number">
                                <FormattedMessage id="verifyWearablesPhoto.orderNumber" values={{ orderNumber: orderNumber }} />
                            </div>

                            <p className="btn-container btn-container--center">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={ formik.isSubmitting || !formik.isValid || !formik.dirty } onClick={ formik.handleSubmit }>
                                    <FormattedMessage id="verifyWearablesPhoto.submitCta" />
                                </button>
                            </p>

                        </form>

                    </div>

                </div>

            </div>

        </AppLayout>

    );
});

export default PageVerifyWearablesPhoto;
